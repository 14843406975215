import axios from 'axios';

// Default to localhost if REACT_APP_API_URL is not set
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const forecastApi = {
  getForecast: (data) => api.post('/forecast', data),
  getOptions: () => api.get('/forecast/options')
};

export const supplyChainApi = {
  getOverview: (data) => api.post('/supply-chain/overview', data),
  getJointReplenishment: (params) => api.get('/supply-chain/joint-replenishment', { params }),
  getProducts: () => api.get('/supply-chain/products'),
  getCompanies: () => api.get('/supply-chain/companies'),
  getInventoryHistory: async ({ product, company, echelon, startDate, endDate, metric }) => {
    try {
      const response = await api.get('/supply-chain/inventory-history', {
        params: {
          product,
          company,
          echelon,
          startDate,
          endDate,
          metric
        }
      });
      return response;
    } catch (error) {
      console.error('Error fetching inventory history:', error);
      throw error;
    }
  },
  getOptions: () => api.get('/supply-chain/options'),
  getContainerCapacity: async (fromCompany, toCompany) => {
    return await api.get(`/supply-chain/container-capacity`, {
      params: { fromCompany, toCompany }
    });
  },
  getOrderScoutData: async (companyId) => {
    try {
      const response = await api.get(`/supply-chain/order-scout/${companyId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching OrderScout data:', error);
      throw error;
    }
  }
};

export const ordersApi = {
  getDistributors: () => api.get('/orders/distributors'),
  getRecommendations: (data) => api.post('/orders/recommendations', data)
};

export const uploadApi = {
  uploadFile: async (formData) => {
    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 30000 // 30 second timeout
      });
      
      // If upload was successful, refresh to load new data
      if (response.data.success) {
        console.log('File uploaded successfully. Refreshing page...');
        window.location.reload();
        return response;
      }
      
      // If the file was saved but data loading failed, still consider it a partial success
      if (!response.data.success && response.data.file) {
        console.log('File uploaded but data loading failed. Refreshing page...');
        window.location.reload();
        return response;
      }
      
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      
      // Network error or timeout
      if (!error.response) {
        console.log('Network error occurred, but file might have been saved. Refreshing page...');
        setTimeout(() => window.location.reload(), 1000);
        return;
      }
      
      // If we get a response with a file property, the file was saved
      if (error.response?.data?.file) {
        console.log('File uploaded but processing failed. Refreshing page...');
        window.location.reload();
        return;
      }
      
      throw error;
    }
  }
};

export const jrpApi = {
    processOrders: (selectedCompany, parameters) => 
        api.post('/jrp/process-orders', { selectedCompany, parameters })
};

export default api; 