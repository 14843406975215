import React, { useState, useEffect } from 'react';
import { supplyChainApi, jrpApi } from '../../services/api';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OrderScout = () => {
  // State for company selection and data
  const [companies, setCompanies] = useState({ retailers: [], distributors: [], importers: [] });
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [products, setProducts] = useState([]);
  const [processedProducts, setProcessedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [ordersData, setOrdersData] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(true);
  const [showHeatMap, setShowHeatMap] = useState(false);

  // Parameters for calculations
  const [parameters, setParameters] = useState({
    containerCapacity: '',
    safetyStockMultiplier: '1.5',
    maxCoveragePeriod: '12',
    reviewPeriod: '12',
    containerThreshold: 85
  });

  // Add forecast cache
  const [forecastCache, setForecastCache] = useState({});

  const [selectedForecastMethod, setSelectedForecastMethod] = useState('auto');
  const [forecastParams, setForecastParams] = useState({
    simpleMovingAverage: {
      period: 12
    },
    exponentialSmoothing: {
      alpha: 0.1
    },
    holtWinters: {
      alpha: 0.2,
      beta: 0.1,
      gamma: 0.3,
      period: 12
    }
  });
  const [autoSelectionInfo, setAutoSelectionInfo] = useState(null);
  const [forecastMetrics, setForecastMetrics] = useState(null);

  const forecastMethods = [
    {
      value: 'auto',
      label: 'Automatic Selection',
      params: [] // No parameters needed
    },
    { 
      value: 'simpleMovingAverage', 
      label: 'Simple Moving Average',
      params: [
        { name: 'period', label: 'Period', min: 2, max: 52, step: 1 }
      ]
    },
    { 
      value: 'exponentialSmoothing', 
      label: 'Exponential Smoothing',
      params: [
        { name: 'alpha', label: 'Smoothing Factor (α)', min: 0.01, max: 1, step: 0.01 }
      ]
    },
    { 
      value: 'holtWinters', 
      label: 'Holt-Winters',
      params: [
        { name: 'alpha', label: 'Level Smoothing (α)', min: 0.01, max: 1, step: 0.01 },
        { name: 'beta', label: 'Trend Smoothing (β)', min: 0.01, max: 1, step: 0.01 },
        { name: 'gamma', label: 'Seasonal Smoothing (γ)', min: 0.01, max: 1, step: 0.01 },
        { name: 'period', label: 'Seasonal Period', min: 2, max: 52, step: 1 }
      ]
    }
  ];

  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartTitle, setChartTitle] = useState('');

  // Generate next 12 weeks dates starting from Monday
  useEffect(() => {
    const generateWeeks = () => {
      const weeksList = [];
      const today = new Date();
      
      // Get to next Monday
      const currentDay = today.getDay();
      const daysToAdd = currentDay === 0 ? 1 : (8 - currentDay); // If Sunday, add 1; otherwise add days until next Monday
      
      // Create a new date for next Monday to avoid modifying today
      const nextMonday = new Date(today);
      nextMonday.setDate(today.getDate() + daysToAdd);
      // Reset to midnight to ensure consistent dates
      nextMonday.setHours(0, 0, 0, 0);
      
      // Generate 12 weeks starting from next Monday
      for (let i = 0; i < 12; i++) {
        const weekDate = new Date(nextMonday);
        weekDate.setDate(nextMonday.getDate() + (i * 7));
        weeksList.push(weekDate.toISOString().split('T')[0]);
      }

      console.log('Generated weeks:', weeksList.map(date => ({
        date,
        dayOfWeek: new Date(date).getDay(),
        isMonday: new Date(date).getDay() === 1
      })));

      setWeeks(weeksList);
    };
    generateWeeks();
  }, []);

  // Fetch companies on component mount
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await supplyChainApi.getCompanies();
        setCompanies(response.data);
      } catch (err) {
        setError('Failed to load companies');
      }
    };
    fetchCompanies();
  }, []);

  // Handle company type selection
  const handleCompanyTypeChange = (type) => {
    setCompanyType(type);
    setSelectedCompany('');
    setRelatedCompanies([]);
    setOrdersData([]);
  };

  // Handle company selection
  const handleCompanyChange = async (company) => {
    setSelectedCompany(company);
    setOrdersData([]);

    // Fetch container capacity when company is selected
    if (company) {
      try {
        const response = await supplyChainApi.getContainerCapacity(company);
        if (response.data.capacity) {
          handleParameterChange('containerCapacity', response.data.capacity.toString());
        }
      } catch (error) {
        console.error('Error fetching container capacity:', error);
        // Don't show error to user, just log it
      }
    }
  };

  // Handle parameter changes
  const handleParameterChange = (param, value) => {
    setParameters(prev => ({
      ...prev,
      [param]: value
    }));
  };

  const handleForecastMethodChange = (method) => {
    setSelectedForecastMethod(method);
  };

  const handleParamChange = (param, value) => {
    setForecastParams(prev => ({
      ...prev,
      [selectedForecastMethod]: {
        ...prev[selectedForecastMethod],
        [param]: Number(value)
      }
    }));
  };

  const handleResetParams = (method) => {
    setForecastParams(prev => ({
      ...prev,
      [method]: {
        simpleMovingAverage: { period: 12 },
        exponentialSmoothing: { alpha: 0.1 },
        holtWinters: { alpha: 0.2, beta: 0.1, gamma: 0.3, period: 12 }
      }[method]
    }));
  };

  // Calculate function
  const calculateOrders = async () => {
    setLoading(true);
    setError(null);
    setProgress(0);
    
    try {
      setProgress(25);
      
      // Include forecast method and parameters in the request
      const jrpResponse = await jrpApi.processOrders(selectedCompany, {
        ...parameters,
        forecastMethod: selectedForecastMethod,
        forecastParams: forecastParams[selectedForecastMethod]
      });
      
      if (!jrpResponse.data.success) {
        throw new Error(jrpResponse.data.error || 'Failed to process orders');
      }

      setProgress(75);

      // Update the orders data with the processed results
      setOrdersData(jrpResponse.data.data);
      
      // Update forecast metrics if available
      if (jrpResponse.data.forecastMetrics) {
        if (selectedForecastMethod === 'auto') {
          setAutoSelectionInfo(jrpResponse.data.forecastMetrics);
          setForecastMetrics(null);
        } else {
          setForecastMetrics(jrpResponse.data.forecastMetrics);
          setAutoSelectionInfo(null);
        }
      }
      
      setProgress(100);
      setIsHeaderExpanded(false);
    } catch (err) {
      console.error('Error calculating orders:', err);
      setError(err.message || 'Failed to calculate orders');
    } finally {
      setLoading(false);
    }
  };

  // Add the ForecastConfiguration component
  const ForecastConfiguration = () => (
    <div className="mt-4 grid grid-cols-3 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Forecast Method
        </label>
        <select
          className="w-full rounded-md border-gray-300 shadow-sm"
          value={selectedForecastMethod}
          onChange={(e) => handleForecastMethodChange(e.target.value)}
        >
          {forecastMethods.map(method => (
            <option key={method.value} value={method.value}>
              {method.label}
            </option>
          ))}
        </select>
      </div>

      <div className={selectedForecastMethod === 'auto' ? 'opacity-50' : ''}>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Method Parameters
          </label>
          <button
            onClick={() => handleResetParams(selectedForecastMethod)}
            className="text-xs text-blue-600 hover:text-blue-800"
          >
            Reset to defaults
          </button>
        </div>
        <div className="space-y-2">
          {forecastMethods
            .find(m => m.value === selectedForecastMethod)
            ?.params.map(param => (
              <div key={param.name} className="flex items-center space-x-2">
                <label className="text-sm text-gray-600 w-1/2">
                  {param.label}
                </label>
                <input
                  type="number"
                  className="w-1/2 rounded-md border-gray-300 shadow-sm"
                  value={forecastParams[selectedForecastMethod][param.name]}
                  onChange={(e) => handleParamChange(param.name, e.target.value)}
                  min={param.min}
                  max={param.max}
                  step={param.step}
                  disabled={selectedForecastMethod === 'auto'}
                />
              </div>
            ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Forecast Metrics
        </label>
        {(autoSelectionInfo || forecastMetrics) ? (
          <div className="space-y-2">
            <div className="bg-gray-50 p-3 rounded">
              <p className="text-sm font-medium">
                Method: {autoSelectionInfo ? 
                  forecastMethods.find(m => m.value === autoSelectionInfo.method)?.label :
                  forecastMethods.find(m => m.value === selectedForecastMethod)?.label
                }
              </p>
              <p className="text-sm mt-1">
                Score: {selectedForecastMethod === 'auto' ? 
                  (autoSelectionInfo?.score || 'N/A') : 
                  (forecastMetrics?.score || 'N/A')}
              </p>
              {(autoSelectionInfo?.metrics || forecastMetrics?.metrics) && (
                <div className="mt-2 text-xs text-gray-500">
                  <div>MAPE: {(autoSelectionInfo?.metrics?.mape || forecastMetrics?.metrics?.mape || 0).toFixed(2)}%</div>
                  <div>RMSE: {(autoSelectionInfo?.metrics?.rmse || forecastMetrics?.metrics?.rmse || 0).toFixed(2)}</div>
                  <div>MAE: {(autoSelectionInfo?.metrics?.mae || forecastMetrics?.metrics?.mae || 0).toFixed(2)}</div>
                </div>
              )}
              <div className="mt-2 text-xs text-gray-500">
                {Object.entries(autoSelectionInfo?.params || forecastMetrics?.params || {}).map(([key, value]) => (
                  <div key={key}>
                    {key}: {Number(value).toFixed(3)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-sm text-gray-500">
            No metrics available
          </div>
        )}
      </div>
    </div>
  );

  // Add function to handle product click
  const handleProductClick = (productData) => {
    // Get the cutoff date for historical data (today)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Filter historical data to only include dates before today
    const historicalData = productData.historicalData.filter(week => {
      const weekDate = new Date(week.date);
      return weekDate < today;
    });

    // Combine historical and forecast data
    const combinedData = [
      ...historicalData,
      ...productData.weeklyData.map((week, index) => ({
        ...week,
        date: weeks[index],
        // Only include orderRaised if there's no confirmed inbound
        orderRaised: week.inQuantity ? null : week.orderRaised
      }))
    ];

    // First pass: get historical values and initial state
    let lastPhysicalInventory = null;
    const processedData = combinedData.map((week, index) => {
      if (week.isHistorical) {
        lastPhysicalInventory = week.endingPhysicalInventory;
        return {
          ...week,
          physicalInventory: week.endingPhysicalInventory
        };
      }
      
      // For the first forecast week, use the last historical value as a base
      if (lastPhysicalInventory === null) {
        lastPhysicalInventory = week.beginningPhysicalInventory;
      }

      // Calculate new physical inventory
      const newPhysicalInventory = Math.max(0, lastPhysicalInventory + (week.inQuantity || 0) - (week.outQuantity || 0));
      lastPhysicalInventory = newPhysicalInventory;

      return {
        ...week,
        physicalInventory: newPhysicalInventory
      };
    });

    const data = {
      labels: processedData.map(d => {
        const date = new Date(d.date);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      }),
      datasets: [
        {
          type: 'line',
          label: 'Physical Inventory',
          data: processedData.map(week => week.physicalInventory),
          borderColor: 'rgb(34, 197, 94)',
          backgroundColor: 'rgba(34, 197, 94, 0.5)',
          borderWidth: 2,
          tension: 0,
          yAxisID: 'inventory',
          pointRadius: 0,
          segment: {
            borderDash: context => processedData[context.p1DataIndex]?.isHistorical ? undefined : [5, 5]
          }
        },
        {
          type: 'bar',
          label: 'Inbound',
          data: processedData.map(week => {
            // Only show inbound quantities when they arrive, ignore orderRaised
            return week.inQuantity || 0;
          }),
          backgroundColor: context => {
            const index = context.dataIndex;
            const week = processedData[index];
            if (week?.isHistorical) {
              return 'rgba(54, 162, 235, 0.9)';
            } else if (week?.inQuantity) {
              // Confirmed future inbound
              return 'rgba(54, 162, 235, 0.8)';
            } else {
              // No inbound
              return 'rgba(54, 162, 235, 0.4)';
            }
          },
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1,
          yAxisID: 'orders',
          order: 2,
          barPercentage: 0.8,
          categoryPercentage: 0.9
        },
        {
          type: 'bar',
          label: 'Outbound',
          data: processedData.map(week => week.outQuantity || 0),
          backgroundColor: context => {
            const index = context.dataIndex;
            return processedData[index]?.isHistorical ? 
              'rgba(255, 145, 85, 0.9)' : 
              'rgba(255, 145, 85, 0.6)';
          },
          borderColor: 'rgb(255, 145, 85)',
          borderWidth: 1,
          yAxisID: 'orders',
          order: 2,
          barPercentage: 0.8,
          categoryPercentage: 0.9
        }
      ]
    };

    setChartData(data);
    setChartTitle(productData.product);
    setShowChart(true);
  };

  // Add function to handle totals click
  const handleTotalsClick = () => {
    if (!ordersData.length) return;

    // Calculate historical totals
    const historicalDates = new Set();
    ordersData.forEach(product => {
      product.historicalData.forEach(week => {
        historicalDates.add(week.date);
      });
    });

    const sortedHistoricalDates = Array.from(historicalDates).sort();
    
    const historicalTotals = sortedHistoricalDates.map(date => {
      const totals = ordersData.reduce((acc, product) => {
        const weekData = product.historicalData.find(w => w.date === date);
        if (weekData) {
          return {
            inTotal: acc.inTotal + (weekData.inQuantity || 0),
            outTotal: acc.outTotal + (weekData.outQuantity || 0),
            physicalInventory: acc.physicalInventory + weekData.beginningPhysicalInventory
          };
        }
        return acc;
      }, { inTotal: 0, outTotal: 0, physicalInventory: 0 });

      return {
        date,
        inTotal: totals.inTotal,
        outTotal: totals.outTotal,
        physicalInventory: totals.physicalInventory,
        isHistorical: true
      };
    });

    // Calculate forecast totals
    let runningTotalInventory = historicalTotals.length > 0 ? 
      historicalTotals[historicalTotals.length - 1].physicalInventory : 
      ordersData.reduce((sum, item) => sum + item.weeklyData[0].beginningPhysicalInventory, 0);

    const forecastTotals = weeks.map((week, weekIndex) => {
      const totals = {
        date: week,
        inTotal: ordersData.reduce((sum, item) => sum + (item.weeklyData[weekIndex].inQuantity || 0), 0),
        outTotal: ordersData.reduce((sum, item) => sum + (item.weeklyData[weekIndex].outQuantity || 0), 0),
        isHistorical: false
      };

      // Calculate running total inventory
      runningTotalInventory = Math.max(0, runningTotalInventory + totals.inTotal - totals.outTotal);
      totals.physicalInventory = runningTotalInventory;

      return totals;
    });

    // Combine historical and forecast data
    const combinedData = [...historicalTotals, ...forecastTotals];

    const data = {
      labels: combinedData.map(d => {
        const date = new Date(d.date);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      }),
      datasets: [
        {
          type: 'line',
          label: 'Total Physical Inventory',
          data: combinedData.map(d => d.physicalInventory),
          borderColor: 'rgb(34, 197, 94)',
          backgroundColor: 'rgba(34, 197, 94, 0.5)',
          borderWidth: 2,
          tension: 0,
          yAxisID: 'inventory',
          pointRadius: 0,
          segment: {
            borderDash: context => combinedData[context.p1DataIndex]?.isHistorical ? undefined : [5, 5]
          }
        },
        {
          type: 'bar',
          label: 'Total Inbound',
          data: combinedData.map(d => d.inTotal),
          backgroundColor: context => {
            const index = context.dataIndex;
            return combinedData[index]?.isHistorical ? 
              'rgba(54, 162, 235, 0.9)' : 
              'rgba(54, 162, 235, 0.6)';
          },
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1,
          yAxisID: 'orders',
          order: 2,
          barPercentage: 0.8,
          categoryPercentage: 0.9
        },
        {
          type: 'bar',
          label: 'Total Outbound',
          data: combinedData.map(d => d.outTotal),
          backgroundColor: context => {
            const index = context.dataIndex;
            return combinedData[index]?.isHistorical ? 
              'rgba(255, 145, 85, 0.9)' : 
              'rgba(255, 145, 85, 0.6)';
          },
          borderColor: 'rgb(255, 145, 85)',
          borderWidth: 1,
          yAxisID: 'orders',
          order: 2,
          barPercentage: 0.8,
          categoryPercentage: 0.9
        }
      ]
    };

    setChartData(data);
    setChartTitle('All Products');
    setShowChart(true);
  };

  // Add Chart Modal component
  const ChartModal = () => {
    if (!showChart || !chartData) return null;

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        orders: {
          type: 'linear',
          position: 'left',
          grid: {
            color: 'rgba(0, 0, 0, 0.1)'
          },
          title: {
            display: true,
            text: 'Order Quantity'
          },
          beginAtZero: true
        },
        inventory: {
          type: 'linear',
          position: 'right',
          grid: {
            drawOnChartArea: true,
            color: 'rgba(0, 0, 0, 0.05)'
          },
          title: {
            display: true,
            text: 'Inventory Level'
          },
          beginAtZero: true,
          ticks: {
            padding: 10
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: `Inventory Trend - ${chartTitle}`,
          font: {
            size: 16,
            weight: 'bold'
          }
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label;
            }
          }
        }
      }
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white rounded-lg p-6 w-full max-h-[95vh] flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">{chartTitle}</h3>
            <button 
              onClick={() => setShowChart(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <div className="flex-grow min-h-[700px]">
            <Chart type="bar" data={chartData} options={options} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Collapsible Header Section */}
      <div className="bg-white rounded-lg shadow">
        <div 
          className="p-4 cursor-pointer flex justify-between items-center"
          onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}
        >
          <div className="flex items-center space-x-4">
            <h2 className="text-2xl font-bold">Order Scout</h2>
            {!isHeaderExpanded && selectedCompany && (
              <div className="text-sm text-gray-600 flex items-center">
                <span className="mx-2">|</span>
                <span className="font-medium">
                  {companies[companyType + 's'].find(c => c.id === selectedCompany)?.name || ''}
                </span>
                <span className="mx-2">•</span>
                <span className="text-gray-500">
                  {companyType.charAt(0).toUpperCase() + companyType.slice(1)}
                </span>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="heatMapToggle"
                checked={showHeatMap}
                onChange={(e) => setShowHeatMap(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                onClick={(e) => e.stopPropagation()}
              />
              <label 
                htmlFor="heatMapToggle" 
                className="ml-2 text-sm text-gray-600"
                onClick={(e) => e.stopPropagation()}
              >
                Heat Map
              </label>
            </div>
            <button className="text-gray-500 hover:text-gray-700">
              {isHeaderExpanded ? '▼' : '▶'}
            </button>
          </div>
        </div>
        
        {isHeaderExpanded && (
          <div className="p-6 pt-0">
            {/* Company Type Selection */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <button
                onClick={() => handleCompanyTypeChange('distributor')}
                className={`p-2 rounded ${
                  companyType === 'distributor' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'
                }`}
              >
                Distributor
              </button>
              <button
                onClick={() => handleCompanyTypeChange('importer')}
                className={`p-2 rounded ${
                  companyType === 'importer' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'
                }`}
              >
                Importer
              </button>
            </div>

            {/* Company Selection */}
            {companyType && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select {companyType.charAt(0).toUpperCase() + companyType.slice(1)}
                </label>
                <select
                  value={selectedCompany}
                  onChange={(e) => handleCompanyChange(e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="">Select a company</option>
                  {companies[companyType + 's'].map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Parameters Section */}
            {selectedCompany && (
              <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Container Capacity
                  </label>
                  <input
                    type="number"
                    value={parameters.containerCapacity}
                    onChange={(e) => handleParameterChange('containerCapacity', e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Safety Stock Multiplier
                  </label>
                  <input
                    type="number"
                    value={parameters.safetyStockMultiplier}
                    onChange={(e) => handleParameterChange('safetyStockMultiplier', e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                    step="0.1"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Max Coverage Period
                  </label>
                  <input
                    type="number"
                    value={parameters.maxCoveragePeriod}
                    onChange={(e) => handleParameterChange('maxCoveragePeriod', e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Review Period
                  </label>
                  <input
                    type="number"
                    value={parameters.reviewPeriod}
                    onChange={(e) => handleParameterChange('reviewPeriod', e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Container Threshold (%)
                  </label>
                  <input
                    type="number"
                    value={parameters.containerThreshold}
                    onChange={(e) => handleParameterChange('containerThreshold', e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            )}

            {/* Add Forecast Configuration */}
            <ForecastConfiguration />

            {/* Calculate Button */}
            {selectedCompany && (
              <div className="mt-4">
                <button
                  onClick={calculateOrders}
                  disabled={loading}
                  className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                    ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                >
                  {loading ? 'Calculating...' : 'Calculate Orders'} 
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Progress Bar */}
      {loading && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-4">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          ></div>
          <div className="text-sm text-gray-500 mt-1 text-center">
            Processing... {Math.round(progress)}%
          </div>
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {/* Orders Table with Sticky Headers */}
      {ordersData.length > 0 && (
        <div className="bg-white rounded-lg shadow mt-6">
          <div className={`overflow-y-auto ${isHeaderExpanded ? 'max-h-[calc(100vh-700px)]' : 'max-h-[calc(100vh-200px)]'} transition-all duration-300`}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 z-20 border-r border-gray-300">
                    Product
                  </th>
                  {weeks.map((week, index) => (
                    <th key={week} className="px-3 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-gray-300">
                      Week {index + 1}
                      <div className="text-[10px] font-normal text-gray-400 lowercase">
                        {new Date(week).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                      </div>
                    </th>
                  ))}
                </tr>
                <tr className="bg-gray-50">
                  <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 z-20 border-r border-gray-300">
                    <div className="flex flex-col">
                      <span>Product Details</span>
                      <div className="text-[10px] mt-1 font-normal text-gray-400 normal-case">
                        <div className="flex items-center gap-1">
                          <span className="w-6 text-right">SS:</span>
                          <span>Reorder Point</span>
                        </div>
                      </div>
                    </div>
                  </th>
                  {weeks.map((week) => (
                    <th key={week} className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-gray-300">
                      <div className="grid grid-rows-3 gap-1 relative">
                        {/* Movement row divider */}
                        <div className="absolute top-1/3 left-0 right-0 border-t border-dashed border-gray-200"></div>
                        {/* Physical/IP row divider */}
                        <div className="absolute top-2/3 left-0 right-0 border-t-2 border-gray-300"></div>
                        
                        {/* Movements Row */}
                        <div className="grid grid-cols-2 gap-1 text-center">
                          <div className="text-purple-600 font-semibold relative group">
                            IN
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Physical receipt of orders (after lead time)
                            </div>
                          </div>
                          <div className="text-blue-600 relative group">
                            OUT
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Physical outbound shipments
                            </div>
                          </div>
                        </div>

                        {/* Inventory Position Row */}
                        <div className="grid grid-cols-2 gap-1 text-gray-500">
                          <div className="relative group">
                            BIP
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Beginning Inventory Position (Physical + On Order)
                            </div>
                          </div>
                          <div className="relative group">
                            EIP
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Ending Inventory Position (Physical + On Order)
                            </div>
                          </div>
                        </div>

                        {/* Physical Inventory Row */}
                        <div className="grid grid-cols-2 gap-1">
                          <div className="text-emerald-700 font-semibold relative group">
                            BPI
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Beginning Physical Inventory
                            </div>
                          </div>
                          <div className="text-emerald-700 relative group">
                            EPI
                            <div className="hidden group-hover:block absolute z-30 -bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded p-2 w-48">
                              Ending Physical Inventory (after IN/OUT)
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {(() => {
                  // Pre-calculate volume rankings for each week
                  const weeklyRankings = ordersData[0].weeklyData.map((_, weekIndex) => {
                    // Get volumes for all products in this week
                    const weekVolumes = ordersData.map(item => {
                      const week = item.weeklyData[weekIndex];
                      return {
                        product: item.product,
                        volume: (week.inQuantity || 0) + week.outQuantity
                      };
                    });

                    // Sort by volume to determine ranks
                    const sortedVolumes = [...weekVolumes].sort((a, b) => b.volume - a.volume);
                    const maxVolume = sortedVolumes[0].volume;
                    const minVolume = sortedVolumes[sortedVolumes.length - 1].volume;

                    // Create a map of product to intensity
                    return weekVolumes.reduce((acc, { product, volume }) => {
                      acc[product] = maxVolume === minVolume ? 
                        0 : 
                        (volume - minVolume) / (maxVolume - minVolume);
                      return acc;
                    }, {});
                  });

                  return [...ordersData]
                    .sort((a, b) => a.product.localeCompare(b.product))
                    .map((item, index) => (
                      <tr key={index} className="border-t border-gray-200">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300 cursor-pointer hover:bg-gray-50" onClick={() => handleProductClick(item)}>
                          <div>{item.product}</div>
                          <div className="text-xs text-gray-500">
                            <div>ROP: {item.rop} | TIL: {item.til} | MIL: {item.mil}</div>
                            <div>SS: {Math.round(item.safetyStock)} | MOQ: {item.moq}</div>
                          </div>
                        </td>
                        {item.weeklyData.map((week, weekIndex) => (
                          <td 
                            key={weekIndex} 
                            className={`px-3 py-4 whitespace-nowrap text-sm text-gray-500 border-r border-gray-300 ${
                              week.outQuantity > 0 && week.beginningPhysicalInventory === 0 && !week.inQuantity ? 'bg-gray-100' : ''
                            }`}
                            style={showHeatMap ? {
                              background: `rgba(254, 226, 226, ${weeklyRankings[weekIndex][item.product]})`
                            } : {}}
                          >
                            <div className="grid grid-rows-3 gap-1 relative">
                              {/* Physical/IP row divider */}
                              <div className="absolute top-2/3 left-0 right-0 border-t-2 border-gray-300"></div>
                              
                              {/* Movements Row */}
                              <div className="grid grid-cols-2 gap-1 text-center">
                                <div className="text-purple-600 font-semibold relative">
                                  {week.inQuantity || week.orderRaised ? (
                                    <>
                                      {week.orderRaised && (
                                        <span className="absolute -top-2.5 left-1/2 -translate-x-1/2 text-[11px] text-gray-400 leading-none">
                                          {week.orderRaised}
                                        </span>
                                      )}
                                      {week.inQuantity || '-'}
                                    </>
                                  ) : '-'}
                                </div>
                                <div>
                                    {week.outQuantity > 0 ? (
                                        week.beginningPhysicalInventory === 0 && !week.inQuantity ? (
                                            <span className="text-red-600 font-semibold line-through">{week.outQuantity}</span>
                                        ) : week.beginningPhysicalInventory + (week.inQuantity || 0) < week.outQuantity ? (
                                            <div className="relative">
                                                <span className="text-blue-600">{Math.min(week.beginningPhysicalInventory + (week.inQuantity || 0), week.outQuantity)}</span>
                                                <span className="absolute -top-2.5 left-1/2 -translate-x-1/2 text-[11px] text-gray-400 line-through leading-none">{week.outQuantity}</span>
                                            </div>
                                        ) : (
                                            <span className="text-blue-600">{week.outQuantity}</span>
                                        )
                                    ) : '-'}
                                </div>
                              </div>

                              {/* Inventory Position Row */}
                              <div className="grid grid-cols-2 gap-1 text-center">
                                <div className={
                                  week.bip >= item.rop ? 'text-green-600' : 
                                  week.bip >= item.safetyStock ? 'text-amber-600' : 
                                  'text-red-700'
                                }>
                                  {week.bip}
                                </div>
                                <div className={
                                  week.eip >= item.rop ? 'text-green-600' : 
                                  week.eip >= item.safetyStock ? 'text-amber-600' : 
                                  'text-red-700'
                                }>
                                  {week.eip}
                                </div>
                              </div>

                              {/* Physical Inventory Row */}
                              <div className="grid grid-cols-2 gap-1 text-center">
                                <div className={
                                  week.beginningPhysicalInventory >= item.rop ? 'text-emerald-700 font-semibold' : 
                                  week.beginningPhysicalInventory >= item.safetyStock ? 'text-amber-600 font-semibold' : 
                                  'text-red-700 font-semibold'
                                }>
                                  {week.beginningPhysicalInventory}
                                </div>
                                <div className={
                                  week.endingPhysicalInventory >= item.rop ? 'text-emerald-700 font-semibold' : 
                                  week.endingPhysicalInventory >= item.safetyStock ? 'text-amber-600 font-semibold' : 
                                  'text-red-700 font-semibold'
                                }>
                                  {week.endingPhysicalInventory}
                                </div>
                              </div>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ));
                })()}
              </tbody>
              {/* Sticky Footer */}
              {ordersData.length > 0 && (
                <tfoot className="bg-gray-50 sticky bottom-0 border-t-2 border-gray-300 shadow-lg">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 border-r border-gray-300 cursor-pointer hover:bg-gray-50" onClick={handleTotalsClick}>
                      Weekly Totals
                    </td>
                    {(() => {
                      const weeklyTotals = ordersData[0].weeklyData.map((_, weekIndex) => {
                        const totals = ordersData.reduce((acc, item) => {
                          const week = item.weeklyData[weekIndex];
                          return {
                            inTotal: acc.inTotal + (week.inQuantity || 0),
                            outTotal: acc.outTotal + (week.outQuantity || 0),
                            lostSalesTotal: acc.lostSalesTotal + (week.lostSales || 0),
                            bipTotal: acc.bipTotal + (week.bip || 0),
                            eipTotal: acc.eipTotal + (week.eip || 0),
                            bpiTotal: acc.bpiTotal + week.beginningPhysicalInventory,
                            epiTotal: acc.epiTotal + week.endingPhysicalInventory
                          };
                        }, { inTotal: 0, outTotal: 0, lostSalesTotal: 0, bipTotal: 0, eipTotal: 0, bpiTotal: 0, epiTotal: 0 });

                        return {
                          ...totals,
                          hasLostSales: totals.lostSalesTotal > 0
                        };
                      });

                      return weeklyTotals.map((totals, index) => (
                        <td key={index} className="px-3 py-4 whitespace-nowrap text-sm font-medium border-r border-gray-300">
                          <div className="grid grid-rows-3 gap-1 relative">
                            {/* Physical/IP row divider */}
                            <div className="absolute top-2/3 left-0 right-0 border-t-2 border-gray-300"></div>
                            
                            {/* Movements Row */}
                            <div className="grid grid-cols-2 gap-1 text-center">
                              <div className="text-purple-600 font-bold">{Math.round(totals.inTotal)}</div>
                              <div className={`text-blue-600 font-bold relative ${totals.hasLostSales ? 'bg-red-100' : ''}`}>
                                {Math.round(totals.outTotal)}
                                {totals.hasLostSales && (
                                  <div className="absolute -bottom-4 left-0 right-0 text-[10px] text-red-600 font-semibold">
                                    Lost: {Math.round(totals.lostSalesTotal)}
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* Inventory Position Row */}
                            <div className="grid grid-cols-2 gap-1 text-center">
                              <div className="text-gray-700 font-bold">{Math.round(totals.bipTotal)}</div>
                              <div className="text-gray-700 font-bold">{Math.round(totals.eipTotal)}</div>
                            </div>

                            {/* Physical Inventory Row */}
                            <div className="grid grid-cols-2 gap-1 text-center">
                              <div className="text-emerald-700 font-bold">{Math.round(totals.bpiTotal)}</div>
                              <div className="text-emerald-700 font-bold">{Math.round(totals.epiTotal)}</div>
                            </div>
                          </div>
                        </td>
                      ));
                    })()}
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      )}

      {/* Enhanced Lost Sales Analysis Section */}
      {ordersData.length > 0 && (
        <div className="bg-white rounded-lg shadow mt-4 hidden">
          <div className="border-b border-gray-200 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <svg className="w-5 h-5 text-red-600" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <h3 className="text-lg font-medium text-gray-900">Lost Sales Analysis</h3>
              </div>
              <div className="text-sm text-gray-500">
                Analysis Period: {weeks[0]} to {weeks[weeks.length - 1]}
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Key Metrics */}
              <div className="space-y-4">
                <div className="bg-red-50 rounded-lg p-4 border border-red-200">
                  <div className="text-sm font-medium text-red-800 mb-1">Total Lost Sales</div>
                  <div className="text-3xl font-bold text-red-700">
                    {ordersData.reduce((total, item) => total + (item.totalLostSales || 0), 0)}
                  </div>
                  <div className="text-xs text-red-600 mt-2 flex items-center justify-between">
                    <span>Across {ordersData.filter(item => item.totalLostSales > 0).length} products</span>
                    <span className="font-medium">
                      {((ordersData.reduce((total, item) => total + (item.totalLostSales || 0), 0) / 
                        ordersData.reduce((total, item) => total + (item.weeklyDemand || 0), 0)) * 100).toFixed(1)}% of total demand
                    </span>
                  </div>
                </div>

                <div className="bg-orange-50 rounded-lg p-4 border border-orange-200">
                  <div className="text-sm font-medium text-orange-800 mb-1">Critical Products</div>
                  <div className="text-3xl font-bold text-orange-700">
                    {ordersData.filter(item => 
                      item.totalLostSales > 0 && (item.totalLostSales / (item.weeklyDemand || 1)) > 0.1
                    ).length}
                  </div>
                  <div className="text-xs text-orange-600 mt-2">
                    Products with &gt;10% lost sales ratio
                  </div>
                </div>

                <div className="bg-amber-50 rounded-lg p-4 border border-amber-200">
                  <div className="text-sm font-medium text-amber-800 mb-1">Peak Lost Sales Week</div>
                  <div className="text-3xl font-bold text-amber-700">
                    {(() => {
                      const weeklyTotals = weeks.map((_, index) => ({
                        week: index + 1,
                        total: ordersData.reduce((sum, item) => 
                          sum + (item.weeklyData[index]?.lostSales || 0), 0
                        )
                      }));
                      const maxWeek = weeklyTotals.reduce((max, week) => 
                        week.total > max.total ? week : max, weeklyTotals[0]
                      );
                      return `Week ${maxWeek.week}`;
                    })()}
                  </div>
                  <div className="text-xs text-amber-600 mt-2">
                    Highest volume of lost sales
                  </div>
                </div>
              </div>

              {/* Weekly Lost Sales Trend */}
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <div className="text-sm font-medium text-gray-800 mb-3">Weekly Lost Sales Trend</div>
                <div className="space-y-3">
                  {weeks.map((week, index) => {
                    const weeklyTotal = ordersData.reduce((total, item) => 
                      total + (item.weeklyData[index]?.lostSales || 0), 0
                    );
                    const weeklyDemand = ordersData.reduce((total, item) => 
                      total + (item.weeklyData[index]?.outQuantity || 0), 0
                    );
                    const lostSalesRatio = weeklyDemand ? (weeklyTotal / weeklyDemand) * 100 : 0;

                    return (
                      <div key={week} className="relative">
                        <div className="flex items-center gap-2 mb-1">
                          <div className="text-xs text-gray-600 w-20">
                            Week {index + 1}
                            <div className="text-[10px] text-gray-400">
                              {new Date(week).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                            </div>
                          </div>
                          <div className="flex-1 h-4 bg-gray-200 rounded-full overflow-hidden">
                            <div 
                              className="h-full bg-gradient-to-r from-red-500 to-red-600 rounded-full"
                              style={{ width: `${Math.min(100, lostSalesRatio)}%` }}
                            ></div>
                          </div>
                          <div className="text-xs font-medium w-32 text-right">
                            <div className="text-red-600">{weeklyTotal} units</div>
                            <div className="text-gray-500 text-[10px]">{lostSalesRatio.toFixed(1)}% of demand</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Products with Lost Sales */}
              <div className="bg-white rounded-lg p-4 border border-gray-200">
                <div className="flex items-center justify-between mb-3">
                  <div className="text-sm font-medium text-gray-800">Critical Products</div>
                  <div className="text-xs text-gray-500">Sorted by impact</div>
                </div>
                <div className="space-y-4 max-h-[400px] overflow-y-auto pr-2">
                  {ordersData
                    .filter(item => item.totalLostSales > 0)
                    .sort((a, b) => b.totalLostSales - a.totalLostSales)
                    .map(item => {
                      const lostSalesRatio = (item.totalLostSales / (item.weeklyDemand || 1)) * 100;
                      const maxWeeklyLostSales = Math.max(...item.weeklyData.map(week => week?.lostSales || 0));
                      const criticalWeeks = item.weeklyData.filter(week => (week?.lostSales || 0) > 0).length;
                      
                      return (
                        <div key={item.product} className="bg-gray-50 rounded-lg p-3">
                          <div className="flex items-center justify-between mb-2">
                            <div className="text-sm font-medium text-gray-700">{item.product}</div>
                            <div className="text-xs font-medium text-red-600">
                              {item.totalLostSales} units lost
                            </div>
                          </div>
                          <div className="space-y-2">
                            <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                              <div 
                                className="h-full bg-gradient-to-r from-red-500 to-red-600 rounded-full"
                                style={{ width: `${Math.min(100, lostSalesRatio)}%` }}
                              ></div>
                            </div>
                            <div className="flex items-center justify-between text-xs text-gray-500">
                              <div>{lostSalesRatio.toFixed(1)}% of demand</div>
                              <div>Peak: {maxWeeklyLostSales} units</div>
                              <div>{criticalWeeks} affected weeks</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/* Additional Insights */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Inventory Position Analysis */}
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <div className="text-sm font-medium text-gray-800 mb-3">Inventory Position Analysis</div>
                <div className="space-y-2">
                  {ordersData
                    .filter(item => item.totalLostSales > 0)
                    .sort((a, b) => b.totalLostSales - a.totalLostSales)
                    .slice(0, 5)
                    .map(item => {
                      const avgInventoryPosition = item.weeklyData.reduce((sum, week) => 
                        sum + week.bip, 0) / item.weeklyData.length;
                      const belowRopCount = item.weeklyData.filter(week => 
                        week.bip < item.rop).length;
                      
                      return (
                        <div key={item.product} className="flex items-center justify-between text-xs">
                          <div className="w-1/3">{item.product}</div>
                          <div className="w-1/3 text-center">
                            <span className="font-medium">Avg IP: </span>
                            <span className={avgInventoryPosition < item.rop ? 'text-red-600' : 'text-green-600'}>
                              {avgInventoryPosition.toFixed(0)}
                            </span>
                          </div>
                          <div className="w-1/3 text-right">
                            <span className="font-medium">Below ROP: </span>
                            <span className="text-amber-600">{belowRopCount} weeks</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* Root Cause Indicators */}
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <div className="text-sm font-medium text-gray-800 mb-3">Root Cause Indicators</div>
                <div className="space-y-3">
                  {(() => {
                    const causes = {
                      lowInventory: ordersData.filter(item => 
                        item.weeklyData.some(week => week.beginningPhysicalInventory < item.safetyStock)
                      ).length,
                      stockouts: ordersData.filter(item =>
                        item.weeklyData.some(week => week.beginningPhysicalInventory === 0)
                      ).length,
                      belowRop: ordersData.filter(item =>
                        item.weeklyData.some(week => week.bip < item.rop)
                      ).length
                    };

                    return (
                      <>
                        <div className="flex items-center justify-between text-xs">
                          <span>Products with Stock-outs</span>
                          <span className="font-medium text-red-600">{causes.stockouts} products</span>
                        </div>
                        <div className="flex items-center justify-between text-xs">
                          <span>Products Below Safety Stock</span>
                          <span className="font-medium text-amber-600">{causes.lowInventory} products</span>
                        </div>
                        <div className="flex items-center justify-between text-xs">
                          <span>Products Below ROP</span>
                          <span className="font-medium text-orange-600">{causes.belowRop} products</span>
                        </div>
                      </>
                    );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add the Chart Modal */}
      <ChartModal />
    </div>
  );
};

export default OrderScout; 