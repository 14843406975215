import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
} from 'chart.js';
import { format } from 'date-fns';
import { forecastApi } from '../../services/api';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

// Modify the dashedLine plugin
const dashedLinePlugin = {
  id: 'dashedLine',
  beforeDatasetsDraw: (chart) => {
    if (!chart.data.metadata?.historicalDataLength) return;
    
    const { ctx } = chart;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;
    const datasets = chart.data.datasets;
    const historicalLength = chart.data.metadata.historicalDataLength;

    datasets.forEach((dataset, i) => {
      if (!dataset.data || dataset.data.length <= historicalLength) return;

      // Draw only the forecast portion with dashed line
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.strokeStyle = dataset.borderColor;
      ctx.lineWidth = dataset.borderWidth || 2;

      // Start from last historical point
      const startX = xAxis.getPixelForValue(historicalLength - 1);
      const startY = yAxis.getPixelForValue(dataset.data[historicalLength - 1]);
      
      ctx.moveTo(startX, startY);

      // Draw only the forecast points
      for (let j = historicalLength; j < dataset.data.length; j++) {
        const x = xAxis.getPixelForValue(j);
        const y = yAxis.getPixelForValue(dataset.data[j]);
        ctx.lineTo(x, y);
      }

      ctx.stroke();
      ctx.restore();
    });
  }
};

const RetailerForecast = () => {
  const [selectedRetailer, setSelectedRetailer] = useState('All');
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedForecastMethod, setSelectedForecastMethod] = useState('auto');
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({ retailers: [], items: [] });
  const [forecastParams, setForecastParams] = useState({
    simpleMovingAverage: {
      period: 12
    },
    exponentialSmoothing: {
      alpha: 0.1
    },
    holtWinters: {
      alpha: 0.2,
      beta: 0.1,
      gamma: 0.3,
      period: 12
    }
  });
  const [autoSelectionInfo, setAutoSelectionInfo] = useState(null);
  const [forecastMetrics, setForecastMetrics] = useState(null);
  const [optimalParams, setOptimalParams] = useState({});
  const [shouldForecast, setShouldForecast] = useState(false);
  const prevParamsRef = useRef(null);

  const forecastMethods = [
    {
      value: 'auto',
      label: 'Automatic Selection',
      params: [] // No parameters needed
    },
    { 
      value: 'simpleMovingAverage', 
      label: 'Simple Moving Average',
      params: [
        { name: 'period', label: 'Period', min: 2, max: 52, step: 1 }
      ]
    },
    { 
      value: 'exponentialSmoothing', 
      label: 'Exponential Smoothing',
      params: [
        { name: 'alpha', label: 'Smoothing Factor (α)', min: 0.01, max: 1, step: 0.01 }
      ]
    },
    { 
      value: 'holtWinters', 
      label: 'Holt-Winters',
      params: [
        { name: 'alpha', label: 'Level Smoothing (α)', min: 0.01, max: 1, step: 0.01 },
        { name: 'beta', label: 'Trend Smoothing (β)', min: 0.01, max: 1, step: 0.01 },
        { name: 'gamma', label: 'Seasonal Smoothing (γ)', min: 0.01, max: 1, step: 0.01 },
        { name: 'period', label: 'Seasonal Period', min: 2, max: 52, step: 1 }
      ]
    }
  ];

  // Fetch available options and set initial item
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await forecastApi.getOptions();
        setOptions(response.data.options);
        // Set initial item selection
        if (response.data.options.items.length > 0) {
          setSelectedItem(response.data.options.items[0]);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
        setError('Failed to load options');
      }
    };

    fetchOptions();
  }, []);

  const handleParamChange = (param, value) => {
    setForecastParams(prev => ({
      ...prev,
      [selectedForecastMethod]: {
        ...prev[selectedForecastMethod],
        [param]: Number(value)
      }
    }));
    setShouldForecast(true);
  };

  // Generate forecast whenever selection changes
  useEffect(() => {
    if (selectedItem && shouldForecast) {
      // Compare current params with previous params
      const currentParams = JSON.stringify({
        retailer: selectedRetailer,
        item: selectedItem,
        method: selectedForecastMethod,
        params: forecastParams[selectedForecastMethod]
      });

      if (currentParams !== prevParamsRef.current) {
        fetchForecast();
        prevParamsRef.current = currentParams;
      }
      setShouldForecast(false);
    }
  }, [selectedRetailer, selectedItem, selectedForecastMethod, forecastParams, shouldForecast]);

  const fetchForecast = async () => {
    try {
      setLoading(true);
      console.log('Fetching forecast for:', {
        retailer: selectedRetailer,
        item: selectedItem,
        method: selectedForecastMethod
      });

      const response = await forecastApi.getForecast({
        retailer: selectedRetailer,
        item: selectedItem,
        forecastMethod: selectedForecastMethod,
        forecastParams: forecastParams[selectedForecastMethod]
      });

      // Log raw data for verification
      if (response.data.originalData) {
        const sept16Data = response.data.originalData.filter(record => 
          record.master_week === '2024-09-16' && 
          record.echelon === 'Retailer'
        );
        
        // Log raw records first
        console.log('Raw September 16 records:', sept16Data.map(r => ({
          retailer: r.retailer,
          distributor: r.distributor,
          outbound: Number(r.outbound_quantity),
          raw: r.outbound_quantity
        })));

        // Group by retailer and distributor
        const byRetailerAndDistributor = {};
        sept16Data.forEach(record => {
          const retailer = record.retailer;
          const distributor = record.distributor;
          if (!byRetailerAndDistributor[retailer]) {
            byRetailerAndDistributor[retailer] = {};
          }
          if (!byRetailerAndDistributor[retailer][distributor]) {
            byRetailerAndDistributor[retailer][distributor] = 0;
          }
          byRetailerAndDistributor[retailer][distributor] += Number(record.outbound_quantity);
        });

        // Log detailed breakdown
        console.log('September 16 by retailer and distributor:', byRetailerAndDistributor);

        // Calculate totals by retailer
        const retailerTotals = {};
        Object.entries(byRetailerAndDistributor).forEach(([retailer, distributors]) => {
          retailerTotals[retailer] = Object.values(distributors).reduce((sum, val) => sum + val, 0);
        });

        console.log('September 16 retailer totals:', retailerTotals);
        console.log('September 16 grand total:', Object.values(retailerTotals).reduce((sum, val) => sum + val, 0));
      }

      // Log chart data details
      if (response.data.chartData?.datasets) {
        const totalDataset = response.data.chartData.datasets.find(d => d.label === 'Total');
        const sept16Index = response.data.chartData.labels.findIndex(l => l === '2024-09-16');
        
        console.log('Chart data verification:', {
          sept16Value: sept16Index >= 0 ? totalDataset.data[sept16Index] : 'Not found',
          datasetLabels: response.data.chartData.datasets.map(d => d.label),
          historicalLength: response.data.chartData.metadata?.historicalDataLength
        });
      }

      setChartData(response.data.chartData);
      
      // Set forecast metrics from response
      if (response.data.chartData.metadata) {
        const metadata = response.data.chartData.metadata;
        console.log('Detailed Forecast metadata:', {
          raw: metadata,
          score: metadata.score,
          selectedMethod: metadata.selectedMethod,
          params: metadata.selectedParams,
          metrics: metadata.metrics
        });
        
        if (selectedForecastMethod === 'auto') {
          const autoInfo = {
            method: metadata.selectedMethod,
            score: metadata.score,
            params: metadata.selectedParams,
            metrics: metadata.metrics
          };
          console.log('Setting autoSelectionInfo:', autoInfo);
          setAutoSelectionInfo(autoInfo);
          setForecastMetrics(null);
        } else {
          const metrics = {
            method: metadata.selectedMethod || selectedForecastMethod,
            score: metadata.score,
            params: metadata.selectedParams || forecastParams[selectedForecastMethod],
            metrics: metadata.metrics
          };
          console.log('Setting forecastMetrics:', metrics);
          setAutoSelectionInfo(null);
          setForecastMetrics(metrics);
        }
      }

      setError(null);
    } catch (error) {
      console.error('Error fetching forecast:', error);
      setError('Failed to fetch forecast data');
    } finally {
      setLoading(false);
    }
  };

  const calculateDemandMetrics = (chartData) => {
    if (!chartData?.datasets || !chartData.metadata) return null;

    const historicalLength = chartData.metadata.historicalDataLength;
    const metrics = {};

    // Calculate for each dataset
    chartData.datasets.forEach(dataset => {
      const historicalData = dataset.data.slice(0, historicalLength);
      const forecastData = dataset.data.slice(historicalLength);

      const historicalTotal = historicalData.reduce((sum, val) => sum + val, 0);
      const forecastTotal = forecastData.reduce((sum, val) => sum + val, 0);
      
      const historicalAvg = historicalTotal / historicalLength;
      const forecastAvg = forecastTotal / forecastData.length;
      
      const growthRate = ((forecastAvg - historicalAvg) / historicalAvg) * 100;

      // Use 'Total' as the key for single retailer data
      const key = selectedRetailer === 'All' ? dataset.label : 'Total';
      metrics[key] = {
        historicalTotal: historicalTotal.toFixed(0),
        forecastTotal: forecastTotal.toFixed(0),
        historicalAvg: historicalAvg.toFixed(1),
        forecastAvg: forecastAvg.toFixed(1),
        growthRate: growthRate.toFixed(1)
      };
    });

    return metrics;
  };

  const handleForecastMethodChange = (method) => {
    setSelectedForecastMethod(method);
    
    if (optimalParams[method]) {
      setForecastParams(prev => ({
        ...prev,
        [method]: optimalParams[method]
      }));
    }
    setShouldForecast(true);
  };

  const handleResetParams = (method) => {
    setForecastParams(prev => ({
      ...prev,
      [method]: {
        simpleMovingAverage: { period: 12 },
        exponentialSmoothing: { alpha: 0.1 },
        holtWinters: { alpha: 0.2, beta: 0.1, gamma: 0.3, period: 12 }
      }[method]
    }));
    setShouldForecast(true);
  };

  // Add this useEffect to trigger initial forecast
  useEffect(() => {
    if (selectedItem) {
      setShouldForecast(true);
    }
  }, [selectedItem]);

  const handleRetailerChange = (retailer) => {
    setSelectedRetailer(retailer);
    setSelectedForecastMethod('auto');
    setAutoSelectionInfo(null);
    setForecastMetrics(null);
    setOptimalParams({});
    setShouldForecast(true);
  };

  const handleItemChange = (item) => {
    setSelectedItem(item);
    // If we're in auto mode, reset to auto
    if (selectedForecastMethod !== 'auto') {
      setSelectedForecastMethod('auto');
    }
    setShouldForecast(true);
  };

  const ForecastConfiguration = () => (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-4">Forecast Configuration</h3>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Forecast Method
          </label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm"
            value={selectedForecastMethod}
            onChange={(e) => handleForecastMethodChange(e.target.value)}
          >
            {forecastMethods.map(method => (
              <option key={method.value} value={method.value}>
                {method.label}
              </option>
            ))}
          </select>
        </div>

        <div className={selectedForecastMethod === 'auto' ? 'opacity-50' : ''}>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Method Parameters
            </label>
            {optimalParams[selectedForecastMethod] && (
              <div className="flex items-center gap-2">
                <span className="text-xs text-green-600">
                  Using optimal parameters
                </span>
                <button
                  onClick={() => handleResetParams(selectedForecastMethod)}
                  className="text-xs text-blue-600 hover:text-blue-800"
                >
                  Reset to defaults
                </button>
              </div>
            )}
          </div>
          <div className="space-y-2">
            {forecastMethods
              .find(m => m.value === selectedForecastMethod)
              ?.params.map(param => (
                <div key={param.name} className="flex items-center space-x-2">
                  <label className="text-sm text-gray-600 w-1/2">
                    {param.label}
                  </label>
                  <input
                    type="number"
                    className="w-1/2 rounded-md border-gray-300 shadow-sm"
                    value={forecastParams[selectedForecastMethod][param.name]}
                    onChange={(e) => handleParamChange(param.name, e.target.value)}
                    min={param.min}
                    max={param.max}
                    step={param.step}
                    disabled={selectedForecastMethod === 'auto'}
                  />
                </div>
              ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Forecast Metrics
          </label>
          {(autoSelectionInfo || forecastMetrics) ? (
            <div className="space-y-2">
              <div className="bg-gray-50 p-3 rounded">
                <p className="text-sm font-medium">
                  Method: {autoSelectionInfo ? 
                    forecastMethods.find(m => m.value === autoSelectionInfo.method)?.label :
                    forecastMethods.find(m => m.value === selectedForecastMethod)?.label
                  }
                </p>
                <p className="text-sm mt-1">
                  Score: {selectedForecastMethod === 'auto' ? 
                    (autoSelectionInfo?.score || 'N/A') : 
                    (forecastMetrics?.score || 'N/A')}
                </p>
                {(autoSelectionInfo?.metrics || forecastMetrics?.metrics) && (
                  <div className="mt-2 text-xs text-gray-500">
                    <div>MAPE: {(autoSelectionInfo?.metrics?.mape || forecastMetrics?.metrics?.mape || 0).toFixed(2)}%</div>
                    <div>RMSE: {(autoSelectionInfo?.metrics?.rmse || forecastMetrics?.metrics?.rmse || 0).toFixed(2)}</div>
                    <div>MAE: {(autoSelectionInfo?.metrics?.mae || forecastMetrics?.metrics?.mae || 0).toFixed(2)}</div>
                  </div>
                )}
                <div className="mt-2 text-xs text-gray-500">
                  {Object.entries(autoSelectionInfo?.params || forecastMetrics?.params || {}).map(([key, value]) => (
                    <div key={key}>
                      {key}: {Number(value).toFixed(3)}
                    </div>
                  ))}
                </div>
              </div>
              <p className="text-xs text-gray-500">
                Lower score indicates better forecast accuracy
              </p>
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              No metrics available
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Retailer Demand Forecast</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Select Retailer
            </label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              value={selectedRetailer}
              onChange={(e) => handleRetailerChange(e.target.value)}
            >
              {options.retailers.map(retailer => (
                <option key={retailer} value={retailer}>
                  {retailer === 'All' ? 'All Retailers' : retailer}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Select Item
            </label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              value={selectedItem}
              onChange={(e) => handleItemChange(e.target.value)}
            >
              {options.items.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <ForecastConfiguration />

      {error && (
        <div className="text-sm text-red-600 bg-red-50 p-3 rounded">
          {error}
        </div>
      )}
      {loading && (
        <div className="text-sm text-blue-600 bg-blue-50 p-3 rounded">
          Generating forecast...
        </div>
      )}

      {chartData && (
        <>
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4">
              Forecast Results - {forecastMethods.find(m => m.value === selectedForecastMethod)?.label}
            </h3>
            <div className="h-96">
              <Line
                data={{
                  ...chartData,
                  datasets: chartData.datasets.map(dataset => ({
                    ...dataset,
                    borderDash: undefined,
                    segment: {
                      borderDash: ctx => 
                        ctx.p1.parsed.x >= chartData.metadata.historicalDataLength ? 
                        [5, 5] : undefined,
                    }
                  }))
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    intersect: false,
                    mode: 'index'
                  },
                  elements: {
                    line: {
                      tension: 0.4
                    }
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Quantity'
                      }
                    },
                    x: {
                      title: {
                        display: true,
                        text: 'Week'
                      },
                      ticks: {
                        maxTicksLimit: 12,
                        callback: function(value) {
                          const date = new Date(this.getLabelForValue(value));
                          return format(date, 'M/d/yy');
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      position: 'top',
                      align: 'start'
                    },
                    title: {
                      display: true,
                      text: [
                        `${selectedItem} - ${selectedRetailer === 'All' ? 'All Retailers' : selectedRetailer}`,
                        selectedForecastMethod === 'auto' && autoSelectionInfo ? 
                          `Auto-selected method: ${forecastMethods.find(m => m.value === autoSelectionInfo.method)?.label}` : 
                          undefined
                      ].filter(Boolean)
                    },
                    tooltip: {
                      callbacks: {
                        title: function(context) {
                          const date = new Date(context[0].label);
                          return format(date, 'M/d/yy');
                        },
                        label: function(context) {
                          const value = context.parsed.y;
                          const isHistorical = context.dataIndex < chartData.metadata.historicalDataLength;
                          return `${context.dataset.label} (${isHistorical ? 'Historical' : 'Forecast'}): ${value.toFixed(1)}`;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          
          {/* Remove ForecastMetrics component call */}
        </>
      )}

      {chartData && (
        <div className="bg-white p-6 rounded-lg shadow mt-6">
          <h3 className="text-xl font-semibold mb-4">Demand Statistics Metrics</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Metric
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Historical
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Forecasted
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Change
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {calculateDemandMetrics(chartData)?.['Total'] && Object.entries(calculateDemandMetrics(chartData)).map(([dataset, metrics]) => (
                  <React.Fragment key={dataset}>
                    <tr className="bg-gray-50">
                      <td colSpan="4" className="px-6 py-2 text-sm font-medium text-gray-900">
                        {dataset}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-2 text-sm text-gray-500">Total Demand</td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {Number(metrics.historicalTotal).toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {Number(metrics.forecastTotal).toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        <span className={`${Number(metrics.growthRate) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {metrics.growthRate}%
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-2 text-sm text-gray-500">Average Weekly Demand</td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {Number(metrics.historicalAvg).toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {Number(metrics.forecastAvg).toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        <span className={`${Number(metrics.growthRate) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {metrics.growthRate}%
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default RetailerForecast; 