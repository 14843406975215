import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import vintafloLogo from '../../assets/vintaflow-logo.png';
import vLogo from '../../assets/vLogo.svg';
import FileUpload from './FileUpload';

const Sidebar = ({ activeView, setActiveView, isCollapsed, setIsCollapsed }) => {
  const navigate = useNavigate();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const menuItems = [
    {
      id: 'forecast',
      label: 'Retailer Forecast',
      icon: '📊'
    },
    {
      id: 'overview',
      label: 'Supply Chain Overview',
      icon: '🔄'
    },
    {
      id: 'xover',
      label: 'Period Over Period',
      icon: '📈'
    },
    {
      id: 'joint-replenishment',
      label: 'Joint Replenishment',
      icon: '🚛'
    },
    {
      id: 'order-scout',
      label: 'Order Scout',
      icon: '🎯'
    }
  ];

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className={`${isCollapsed ? 'w-14' : 'w-64'} min-w-fit bg-white shadow-sm flex flex-col h-full relative transition-all duration-300`}>
      {/* Logo Section */}
      <div 
        className="p-3 border-b border-gray-200 bg-gradient-to-b from-blue-50 to-white cursor-pointer hover:bg-blue-50 transition-colors duration-150"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? (
          <img 
            src={vLogo} 
            alt="V Logo" 
            className="h-7 w-auto mx-auto"
          />
        ) : (
          <>
            <img 
              src={vintafloLogo} 
              alt="Vintaflo Logo" 
              className="h-10 w-auto mx-auto"
            />
            <div className="text-center mt-2 text-xs font-medium text-gray-600">
              Supply Chain Management
            </div>
          </>
        )}
      </div>

      {/* Navigation Menu */}
      <div className="flex-1 px-2 py-3">
        <div className="space-y-1">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => {
                setActiveView(item.id);
                if (!isCollapsed) {
                  setIsCollapsed(true);
                }
              }}
              className={`w-full flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150 whitespace-nowrap ${
                activeView === item.id
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
              title={isCollapsed ? item.label : ''}
            >
              <span className={isCollapsed ? '' : 'mr-2'}>{item.icon}</span>
              {!isCollapsed && item.label}
            </button>
          ))}
        </div>
      </div>

      {/* File Upload Section */}
      <div className={`p-3 border-t border-gray-200 ${isCollapsed ? 'hidden' : ''}`}>
        <FileUpload onUploadSuccess={handleUploadSuccess} />
      </div>

      {/* Logout Section */}
      <div className="p-3 border-t border-gray-200">
        <button
          onClick={handleLogout}
          className={`w-full flex items-center px-2 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150`}
          title={isCollapsed ? 'Logout' : ''}
        >
          <span className={isCollapsed ? '' : 'mr-2'}>🚪</span>
          {!isCollapsed && 'Logout'}
        </button>
      </div>
    </div>
  );
};

export default Sidebar; 