import React, { useState } from 'react';
import Sidebar from '../common/Sidebar';
import RetailerForecast from './RetailerForecast';
import SupplyChainOverview from './SupplyChainOverview';
import XOver from './XOver';
import JointReplenishment from './JointReplenishment';
import OrderScout from './OrderScout';

const Dashboard = () => {
  const [activeView, setActiveView] = useState('forecast');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const renderActiveView = () => {
    switch (activeView) {
      case 'forecast':
        return <RetailerForecast />;
      case 'overview':
        return <SupplyChainOverview />;
      case 'xover':
        return <XOver />;
      case 'joint-replenishment':
        return <JointReplenishment />;
      case 'order-scout':
        return <OrderScout />;
      default:
        return <RetailerForecast />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <div className={`flex-shrink-0 transition-all duration-300`}>
        <Sidebar 
          activeView={activeView} 
          setActiveView={setActiveView} 
          isCollapsed={isSidebarCollapsed}
          setIsCollapsed={setIsSidebarCollapsed}
        />
      </div>
      <main className="flex-1 p-4 overflow-auto">
        {renderActiveView()}
      </main>
    </div>
  );
};

export default Dashboard; 