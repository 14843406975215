import React, { useState, useEffect } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { format } from 'date-fns';
import { supplyChainApi } from '../../services/api';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, title, loading, selectedMetric }) => {
  if (loading || !data) {
    return (
      <div className="h-[300px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Ensure data is an array
  const dataArray = Array.isArray(data) ? data : [];
  
  if (dataArray.length === 0) {
    return (
      <div className="h-[300px] flex items-center justify-center text-gray-500">
        No data available
      </div>
    );
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  // Process the flat array into a format we can use
  const processedData = dataArray.reduce((acc, item) => {
    const date = new Date(item.master_week);
    const year = date.getFullYear();
    const month = date.getMonth();
    
    if (!acc[year]) {
      acc[year] = Array(12).fill(null).map(() => ({
        outbound: 0,
        inbound: 0,
        inventory: 0,
        count: 0,
        weeks: new Set() // Track unique weeks
      }));
    }
    
    // Only add data if we haven't seen this week before
    if (!acc[year][month].weeks.has(item.master_week)) {
      acc[year][month].weeks.add(item.master_week);
      acc[year][month].outbound += Number(item.outbound_quantity) || 0;
      acc[year][month].inbound += Number(item.inbound_quantity) || 0;
      acc[year][month].inventory += Number(item.end_inventory) || 0;
      acc[year][month].count++;
    }

    return acc;
  }, {});

  // Calculate averages for inventory only (not for inbound/outbound)
  Object.keys(processedData).forEach(year => {
    processedData[year] = processedData[year].map(month => {
      if (!month || month.count === 0) return null;
      return {
        outbound: month.outbound, // Keep total quantity
        inbound: month.inbound,   // Keep total quantity
        inventory: month.inventory / month.count // Average inventory
      };
    });
  });

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const hasCurrentYearData = processedData[currentYear] && processedData[currentYear].some(d => d !== null && (d.outbound > 0 || d.inbound > 0 || d.inventory > 0));
  const hasPreviousYearData = processedData[previousYear] && processedData[previousYear].some(d => d !== null && (d.outbound > 0 || d.inbound > 0 || d.inventory > 0));

  if (!hasCurrentYearData && !hasPreviousYearData) {
    return (
      <div className="h-[300px] flex items-center justify-center text-gray-500">
        No data available
      </div>
    );
  }

  const metricColors = {
    outbound: 'rgb(54, 162, 235)',
    inbound: 'rgb(255, 99, 132)',
    inventory: 'rgb(75, 192, 192)'
  };

  const metricLabels = {
    outbound: 'Outbound Quantity',
    inbound: 'Inbound Quantity',
    inventory: 'Inventory Level',
    all: 'All Metrics'
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: `${title} - ${metricLabels[selectedMetric]}`
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            return months[context[0].dataIndex];
          },
          label: (context) => {
            const monthIndex = context.dataIndex;
            const value = context.raw;
            const metric = context.dataset.metric || selectedMetric;
            
            if (value === null) return `${context.dataset.label}: No data`;
            
            let lines = [`${context.dataset.label}: ${value.toLocaleString()}`]; // Use toLocaleString for better number formatting
            
            // Add comparisons only for current year data
            if (!context.dataset.isPreviousYear) {
              const currentYearData = processedData[currentYear];
              const previousYearData = processedData[previousYear];
              
              // Month over Month comparison
              if (monthIndex > 0 && currentYearData?.[monthIndex - 1]) {
                const previousMonth = currentYearData[monthIndex - 1]?.[metric];
                const currentMonth = value;
                if (previousMonth !== null && currentMonth !== null && previousMonth !== 0) {
                  const momChange = ((currentMonth - previousMonth) / previousMonth) * 100;
                  lines.push(`vs Last Month: ${momChange.toFixed(1)}%`);
                }
              }
              
              // Year over Year comparison
              if (previousYearData?.[monthIndex]) {
                const previousYearValue = previousYearData[monthIndex]?.[metric];
                const currentYearValue = value;
                if (previousYearValue !== null && currentYearValue !== null && previousYearValue !== 0) {
                  const yoyChange = ((currentYearValue - previousYearValue) / previousYearValue) * 100;
                  lines.push(`vs Last Year: ${yoyChange.toFixed(1)}%`);
                }
              }
            }
            
            return lines;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          text: selectedMetric === 'all' ? 'Quantity' : metricLabels[selectedMetric]
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        }
      },
      ...(selectedMetric === 'all' ? {
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'Inventory Level'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      } : {})
    }
  };

  const chartData = {
    labels: months,
    datasets: selectedMetric === 'all' ? [
      // Current Year - Outbound
      {
        label: `${currentYear} Outbound`,
        metric: 'outbound',
        data: processedData[currentYear]?.map(d => d?.outbound ?? null) || [],
        borderColor: metricColors.outbound,
        backgroundColor: `${metricColors.outbound}50`,
        tension: 0.4,
        borderWidth: 2,
        yAxisID: 'y'
      },
      // Previous Year - Outbound
      {
        label: `${previousYear} Outbound`,
        metric: 'outbound',
        data: processedData[previousYear]?.map(d => d?.outbound ?? null) || [],
        borderColor: metricColors.outbound,
        backgroundColor: 'transparent',
        tension: 0.4,
        borderWidth: 2,
        borderDash: [5, 5],
        yAxisID: 'y',
        isPreviousYear: true
      },
      // Current Year - Inbound
      {
        label: `${currentYear} Inbound`,
        metric: 'inbound',
        data: processedData[currentYear]?.map(d => d?.inbound ?? null) || [],
        borderColor: metricColors.inbound,
        backgroundColor: `${metricColors.inbound}50`,
        tension: 0.4,
        borderWidth: 2,
        yAxisID: 'y'
      },
      // Previous Year - Inbound
      {
        label: `${previousYear} Inbound`,
        metric: 'inbound',
        data: processedData[previousYear]?.map(d => d?.inbound ?? null) || [],
        borderColor: metricColors.inbound,
        backgroundColor: 'transparent',
        tension: 0.4,
        borderWidth: 2,
        borderDash: [5, 5],
        yAxisID: 'y',
        isPreviousYear: true
      },
      // Current Year - Inventory (on secondary axis)
      {
        label: `${currentYear} Inventory`,
        metric: 'inventory',
        data: processedData[currentYear]?.map(d => d?.inventory ?? null) || [],
        borderColor: metricColors.inventory,
        backgroundColor: `${metricColors.inventory}50`,
        tension: 0.4,
        borderWidth: 2,
        yAxisID: 'y1'
      },
      // Previous Year - Inventory (on secondary axis)
      {
        label: `${previousYear} Inventory`,
        metric: 'inventory',
        data: processedData[previousYear]?.map(d => d?.inventory ?? null) || [],
        borderColor: metricColors.inventory,
        backgroundColor: 'transparent',
        tension: 0.4,
        borderWidth: 2,
        borderDash: [5, 5],
        yAxisID: 'y1',
        isPreviousYear: true
      }
    ] : [
      // Single Metric - Current Year
      {
        label: `${currentYear} ${metricLabels[selectedMetric]}`,
        data: processedData[currentYear]?.map(d => d?.[selectedMetric] ?? null) || [],
        borderColor: metricColors[selectedMetric],
        backgroundColor: `${metricColors[selectedMetric]}50`,
        tension: 0.4,
        borderWidth: 2
      },
      // Single Metric - Previous Year
      {
        label: `${previousYear} ${metricLabels[selectedMetric]}`,
        data: processedData[previousYear]?.map(d => d?.[selectedMetric] ?? null) || [],
        borderColor: metricColors[selectedMetric],
        backgroundColor: 'transparent',
        tension: 0.4,
        borderDash: [5, 5],
        borderWidth: 2,
        isPreviousYear: true
      }
    ]
  };

  return (
    <div className="h-[300px]">
      <Chart type="line" data={chartData} options={options} />
    </div>
  );
};

const XOver = () => {
  const [selectedProduct, setSelectedProduct] = useState('All');
  const [selectedMetric, setSelectedMetric] = useState('outbound');
  const [products, setProducts] = useState(['All']);
  const [historicalData, setHistoricalData] = useState({
    retailer: [],
    distributor: [],
    importer: []
  });
  const [loadingStates, setLoadingStates] = useState({
    retailer: false,
    distributor: false,
    importer: false
  });
  const [error, setError] = useState(null);

  // Fetch initial product list
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const optionsResponse = await supplyChainApi.getOptions();

        if (optionsResponse.data) {
          const filteredProducts = optionsResponse.data.products?.filter(p => p !== 'All') || [];
          setProducts(['All', ...filteredProducts]);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load initial data');
      }
    };

    fetchInitialData();
  }, []);

  // Helper function to fetch data for a single echelon
  const fetchEchelonData = async (product, echelon) => {
    try {
      setLoadingStates(prev => ({ ...prev, [echelon]: true }));

      const endDate = new Date();
      const startDate = new Date(endDate.getFullYear() - 2, 0, 1);

      const response = await supplyChainApi.getInventoryHistory({
        product,
        company: 'All',
        echelon,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        metric: selectedMetric === 'all' ? 'all' : selectedMetric
      });

      setHistoricalData(prev => ({
        ...prev,
        [echelon]: Array.isArray(response.data) ? response.data : []
      }));
    } catch (error) {
      console.error(`Error fetching ${echelon} data:`, error);
      setError(`Failed to fetch ${echelon} data: ${error.message}`);
      setHistoricalData(prev => ({
        ...prev,
        [echelon]: []
      }));
    } finally {
      setLoadingStates(prev => ({ ...prev, [echelon]: false }));
    }
  };

  // Fetch data progressively when product or metric changes
  useEffect(() => {
    if (!selectedProduct) return;

    const echelons = ['retailer', 'distributor', 'importer'];
    
    // Reset data when product or metric changes
    setHistoricalData({
      retailer: [],
      distributor: [],
      importer: []
    });
    
    // Fetch data for each echelon with a slight delay between each
    echelons.forEach((echelon, index) => {
      setTimeout(() => {
        fetchEchelonData(selectedProduct, echelon);
      }, index * 100); // 100ms delay between each echelon
    });
  }, [selectedProduct, selectedMetric]);

  return (
    <div className="space-y-6">
      {/* Selection Controls */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Historical Flow Analysis</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Product
            </label>
            <select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              {products.map(product => (
                <option key={product} value={product}>{product}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Metric
            </label>
            <select
              value={selectedMetric}
              onChange={(e) => setSelectedMetric(e.target.value)}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="all">All Metrics</option>
              <option value="outbound">Outbound Orders</option>
              <option value="inbound">Inbound Orders</option>
              <option value="inventory">Inventory Level</option>
            </select>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      <div className="space-y-6">
        {/* Retailer Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <LineChart
            data={historicalData.retailer}
            title="Retailer Flow Analysis"
            loading={loadingStates.retailer}
            selectedMetric={selectedMetric}
          />
        </div>

        {/* Distributor Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <LineChart
            data={historicalData.distributor}
            title="Distributor Flow Analysis"
            loading={loadingStates.distributor}
            selectedMetric={selectedMetric}
          />
        </div>

        {/* Importer Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <LineChart
            data={historicalData.importer}
            title="Importer Flow Analysis"
            loading={loadingStates.importer}
            selectedMetric={selectedMetric}
          />
        </div>
      </div>
    </div>
  );
};

export default XOver; 