import React, { useState, useEffect } from 'react';
import { supplyChainApi } from '../../services/api';

const JointReplenishment = () => {
  const [fromCompany, setFromCompany] = useState('');
  const [toCompany, setToCompany] = useState('');
  const [containerCapacity, setContainerCapacity] = useState('');
  const [safetyStockMultiplier, setSafetyStockMultiplier] = useState('1.5');
  const [maxCoveragePeriod, setMaxCoveragePeriod] = useState('8');
  const [reviewPeriod, setReviewPeriod] = useState('1');
  const [orderRecommendations, setOrderRecommendations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [containerThreshold, setContainerThreshold] = useState(85);

  // Update useEffect to fetch container capacity when both companies are selected
  useEffect(() => {
    const fetchContainerCapacity = async () => {
      if (fromCompany && toCompany) {
        try {
          const response = await supplyChainApi.getContainerCapacity(fromCompany, toCompany);
          if (response.data.capacity) {
            setContainerCapacity(response.data.capacity.toString());
          }
        } catch (error) {
          console.error('Error fetching container capacity:', error);
          // Don't show error to user, just log it
        }
      }
    };

    fetchContainerCapacity();
  }, [fromCompany, toCompany]);

  // Modify the handleFromCompanyChange
  const handleFromCompanyChange = (event) => {
    setFromCompany(event.target.value);
    setOrderRecommendations(null);
    setError(null);
  };

  // Modify the handleToCompanyChange
  const handleToCompanyChange = (event) => {
    setToCompany(event.target.value);
    setOrderRecommendations(null);
    setError(null);
  };

  const [companies, setCompanies] = useState({ retailers: [], distributors: [], importers: [] });

  // Add new state for calculation details
  const [calculationDetails, setCalculationDetails] = useState(null);

  // Function to get next week's date in YYYY-MM-DD format
  const getNextWeek = () => {
    const today = new Date();
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return nextWeek.toISOString().split('T')[0];
  };

  // Fetch companies on component mount
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await supplyChainApi.getCompanies();
        setCompanies(response.data);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Failed to load companies');
      }
    };
    fetchCompanies();
  }, []);

  const handleParameterChange = (param, value) => {
    console.log('Parameter changed:', param, value);
    switch (param) {
      case 'containerCapacity':
        setContainerCapacity(value);
        break;
      case 'safetyStockMultiplier':
        setSafetyStockMultiplier(value);
        break;
      case 'maxCoveragePeriod':
        setMaxCoveragePeriod(value);
        break;
      case 'reviewPeriod':
        setReviewPeriod(value);
        break;
      default:
        console.warn('Unknown parameter:', param);
    }
  };

  const fetchJointOrder = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const requestParams = {
        fromCompany,
        toCompany,
        containerCapacity,
        safetyStockMultiplier,
        reviewPeriod,
        maxCoveragePeriod,
        containerThreshold
      };
      
      console.log('Making request with params:', requestParams);
      
      const response = await supplyChainApi.getJointReplenishment(requestParams);
      
      console.log('Response received:', response.data);
      setOrderRecommendations(response.data);
      setCalculationDetails(response.data.calculationDetails);
    } catch (err) {
      console.error('Error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status
      });
      setError(err.response?.data?.error || 'Failed to calculate joint replenishment order');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submit clicked');
    if (fromCompany && toCompany) {
      console.log('Fetching joint order...');
      fetchJointOrder();
    } else {
      console.log('Missing required fields:', {
        fromCompany,
        toCompany
      });
    }
  };

  // Helper function to get available "to" companies based on selected "from" company
  const getAvailableToCompanies = () => {
    if (!fromCompany) return [];
    
    // If selected from company is a retailer, they can order from distributors
    if (companies.retailers.find(c => c.id === fromCompany)) {
      return companies.distributors;
    }
    // If selected from company is a distributor, they can order from importers
    if (companies.distributors.find(c => c.id === fromCompany)) {
      return companies.importers;
    }
    return [];
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-bold mb-4">Joint Replenishment Order</h2>
        
        <form onSubmit={handleSubmit}>
          {/* Parameters Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Replenishment Parameters</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor="containerCapacity" className="block text-sm font-medium text-gray-700">
                  Container Capacity
                </label>
                <input
                  type="number"
                  id="containerCapacity"
                  name="containerCapacity"
                  value={containerCapacity}
                  onChange={(e) => setContainerCapacity(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="containerThreshold" className="block text-sm font-medium text-gray-700">
                  Opportunistic Threshold (%)
                </label>
                <input
                  type="number"
                  id="containerThreshold"
                  name="containerThreshold"
                  value={containerThreshold}
                  onChange={(e) => setContainerThreshold(e.target.value)}
                  min="0"
                  max="100"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="safetyStockMultiplier" className="block text-sm font-medium text-gray-700">
                  Safety Stock Multiplier
                </label>
                <input
                  type="number"
                  id="safetyStockMultiplier"
                  name="safetyStockMultiplier"
                  value={safetyStockMultiplier}
                  onChange={(e) => handleParameterChange('safetyStockMultiplier', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  min="0"
                  step="0.1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Max Coverage Period (weeks)
                </label>
                <input
                  type="number"
                  value={maxCoveragePeriod}
                  onChange={(e) => handleParameterChange('maxCoveragePeriod', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Review Period (weeks)
                </label>
                <input
                  type="number"
                  value={reviewPeriod}
                  onChange={(e) => handleParameterChange('reviewPeriod', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
            </div>
          </div>

          {/* Selection Controls - Now in 2 columns instead of 3 */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                From Company
              </label>
              <select
                value={fromCompany}
                onChange={(e) => {
                  setFromCompany(e.target.value);
                  setToCompany('');
                }}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option value="">Select From Company</option>
                {companies.retailers.map(company => (
                  <option key={company.id} value={company.id}>{company.name} (Retailer)</option>
                ))}
                {companies.distributors.map(company => (
                  <option key={company.id} value={company.id}>{company.name} (Distributor)</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                To Company
              </label>
              <select
                value={toCompany}
                onChange={(e) => setToCompany(e.target.value)}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                disabled={!fromCompany}
              >
                <option value="">Select To Company</option>
                {getAvailableToCompanies().map(company => (
                  <option key={company.id} value={company.id}>{company.name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mb-6">
            <button
              type="submit"
              disabled={!fromCompany || !toCompany || loading}
              className={`px-4 py-2 rounded-md text-white font-medium
                ${(!fromCompany || !toCompany || loading)
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
                }`}
            >
              {loading ? 'Calculating...' : 'Calculate Order'}
            </button>
          </div>
        </form>

        {/* Results Display */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <p>Calculating order recommendation...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        ) : orderRecommendations && orderRecommendations.containers?.length > 0 ? (
          <div className="space-y-6">
            {/* Summary Cards */}
            <div className="grid grid-cols-4 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Recommended Containers</h3>
                <p className="text-2xl font-bold">{orderRecommendations.totalContainers}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Avg Container Utilization</h3>
                <p className="text-2xl font-bold">
                  {orderRecommendations.avgContainerUtilization.toFixed(1)}%
                </p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Total Items</h3>
                <p className="text-2xl font-bold">{orderRecommendations.totalItems}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Total Cases</h3>
                <p className="text-2xl font-bold">{orderRecommendations.totalCases}</p>
              </div>
            </div>

            {/* Container Breakdown */}
            {orderRecommendations.containers.map((container, idx) => (
              <div 
                key={idx} 
                className={`border rounded-lg overflow-hidden ${
                  container.status === 'Not Recommended' 
                    ? 'border-red-300 bg-red-50' 
                    : 'border-gray-200'
                }`}
              >
                <div className={`px-4 py-2 border-b ${
                  container.status === 'Not Recommended' 
                    ? 'bg-red-100' 
                    : 'bg-gray-50'
                }`}>
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-semibold">Container {idx + 1}</h3>
                      <p className="text-sm text-gray-600">
                        Cases: {container.totalCases} | Utilization: {container.utilization.toFixed(1)}%
                      </p>
                    </div>
                    {container.status === 'Not Recommended' ? (
                      <span className="text-red-600 text-sm font-medium">
                        Not Recommended - Low Utilization
                      </span>
                    ) : (
                      <span className="text-blue-600 text-sm font-medium">
                        Recommended Container
                      </span>
                    )}
                  </div>
                </div>
                
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Item
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Order Quantity (Cases)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        MOQ (Cases)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Current IP
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        ROP
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Current Coverage (Weeks)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Projected Coverage (Weeks)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Order Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className={`bg-white divide-y divide-gray-200 ${
                    container.status === 'Not Recommended' ? 'bg-red-50' : ''
                  }`}>
                    {container.items.map((item, itemIdx) => (
                      <tr key={itemIdx}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.name}
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                          item.orderQuantity < item.moq ? 'text-red-600 font-medium' : 'text-gray-500'
                        }`}>
                          {item.orderQuantity}
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                          item.orderQuantity < item.moq ? 'text-red-600 font-medium' : 'text-gray-500'
                        }`}>
                          {item.moq > 0 ? item.moq : 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.currentIP}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.rop}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.weeksCoverage.toFixed(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.projectedWeeksCoverage.toFixed(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 text-xs ${
                            item.orderType === 'Required'
                              ? 'text-red-600'
                              : item.orderType === 'Opportunistic'
                                ? 'text-green-600'
                                : 'text-blue-600'
                          }`}>
                            {item.orderType}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12 text-gray-500">
            Select companies to calculate joint replenishment orders
          </div>
        )}
      </div>

      {/* Add Calculation Details Table */}
      {calculationDetails && (
        <div className="bg-white p-6 rounded-lg shadow mt-6">
          <h3 className="text-xl font-bold mb-4">Calculation Details</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Product
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Weekly Demand
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Lead Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Safety Stock
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    ROP
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Current IP
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    TIL
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    MaxIL
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Order Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {calculationDetails.map((item, idx) => (
                  <tr key={idx} className={item.needsOrder ? 'bg-yellow-50' : ''}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.weeklyDemand.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.leadTime} weeks
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.safetyStock.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.rop.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.inventoryPosition.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.til.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.maxIL.toFixed(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        item.orderStatus === 'Required Order'
                          ? 'bg-yellow-100 text-yellow-800'
                          : item.orderStatus === 'Opportunistic Order'
                          ? 'bg-blue-100 text-blue-800'
                          : 'bg-green-100 text-green-800'
                      }`}>
                        {item.orderStatus}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Parameter Legend */}
          <div className="mt-4 bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-semibold mb-2">Calculation Parameters:</h4>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <p><span className="font-medium">ROP (Reorder Point)</span> = (Weekly Demand × Lead Time) + Safety Stock</p>
                <p><span className="font-medium">Safety Stock</span> = Weekly Demand × Safety Stock Multiplier ({safetyStockMultiplier})</p>
              </div>
              <div>
                <p><span className="font-medium">TIL (Target Inventory Level)</span> = (Weekly Demand × Lead Time) + Safety Stock + (Weekly Demand × Review Period)</p>
                <p><span className="font-medium">MaxIL</span> = Safety Stock + (Weekly Demand × (Lead Time + Max Coverage Period ({maxCoveragePeriod} weeks)))</p>
              </div>
            </div>
          </div>

          <div className="mt-4 bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-semibold mb-2">Calculation Breakdown:</h4>
            {calculationDetails.map((item, idx) => (
              <div key={idx} className="mb-4 p-4 bg-white rounded shadow">
                <h5 className="font-medium text-lg mb-2">{item.name}</h5>
                <div className="grid grid-cols-1 gap-4 text-sm">
                  <div className="space-y-2">
                    <p className="font-medium">Input Values:</p>
                    <ul className="list-disc pl-5">
                      <li>Weekly Demand: {item.weeklyDemand.toFixed(1)}</li>
                      <li>Lead Time: {item.leadTime} weeks</li>
                      <li>Safety Stock Multiplier: {safetyStockMultiplier}</li>
                    </ul>
                  </div>
                  
                  <div className="space-y-2">
                    <p className="font-medium">Safety Stock Calculation:</p>
                    <div className="pl-5">
                      <p>Weekly Demand × Safety Stock Multiplier</p>
                      <p className="text-gray-600">{item.weeklyDemand.toFixed(1)} × {safetyStockMultiplier} = {item.safetyStock.toFixed(1)}</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="font-medium">ROP Calculation:</p>
                    <div className="pl-5">
                      <p>1. Demand During Lead Time = Weekly Demand × Lead Time</p>
                      <p className="text-gray-600">{item.weeklyDemand.toFixed(1)} × {item.leadTime} = {(item.weeklyDemand * item.leadTime).toFixed(1)}</p>
                      <p>2. ROP = Demand During Lead Time + Safety Stock</p>
                      <p className="text-gray-600">{(item.weeklyDemand * item.leadTime).toFixed(1)} + {item.safetyStock.toFixed(1)} = {item.rop.toFixed(1)}</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="font-medium">TIL Calculation:</p>
                    <div className="pl-5">
                      <p>TIL = Demand During Lead Time + Safety Stock + (Weekly Demand × Review Period)</p>
                      <p className="text-gray-600">
                        {(item.weeklyDemand * item.leadTime).toFixed(1)} + {item.safetyStock.toFixed(1)} + ({item.weeklyDemand.toFixed(1)} × {reviewPeriod}) = {item.til.toFixed(1)}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="font-medium">MaxIL Calculation:</p>
                    <div className="pl-5">
                      <p>1. Safety Stock = Weekly Demand × Safety Stock Multiplier</p>
                      <p className="text-gray-600">{item.weeklyDemand.toFixed(1)} × {safetyStockMultiplier} = {(item.weeklyDemand * safetyStockMultiplier).toFixed(1)}</p>
                      <p>2. Combined Period Demand = Weekly Demand × (Lead Time + Max Coverage Period)</p>
                      <p className="text-gray-600">{item.weeklyDemand.toFixed(1)} × ({item.leadTime} + {maxCoveragePeriod}) = {(item.weeklyDemand * (item.leadTime + Number(maxCoveragePeriod))).toFixed(1)}</p>
                      <p>3. MaxIL = Safety Stock + Combined Period Demand</p>
                      <p className="text-gray-600">{(item.weeklyDemand * safetyStockMultiplier).toFixed(1)} + {(item.weeklyDemand * (item.leadTime + Number(maxCoveragePeriod))).toFixed(1)} = {item.maxIL.toFixed(1)}</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="font-medium">Current Status:</p>
                    <div className="pl-5">
                      <p>Current IP: {item.inventoryPosition.toFixed(1)}</p>
                      <p>ROP: {item.rop.toFixed(1)}</p>
                      <p>TIL: {item.til.toFixed(1)}</p>
                      <p className={
                        item.orderStatus === 'Required Order'
                          ? "text-yellow-600 font-medium"
                          : item.orderStatus === 'Opportunistic Order'
                          ? "text-blue-600 font-medium"
                          : "text-green-600 font-medium"
                      }>
                        Status: {item.orderStatus}
                        {item.orderStatus === 'Required Order' && " (IP ≤ ROP)"}
                        {item.orderStatus === 'Opportunistic Order' && " (ROP < IP ≤ TIL)"}
                        {item.orderStatus === 'No Order Needed' && " (IP > TIL)"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default JointReplenishment; 